<template>
    <div>
        <app-table
            :columns="columns"
            :actions="tableActions"
            url="private-kb"
            ref="table"
            :params="{groups: ['domains']}"
        >
            <template v-slot:item.url="{ item }">
                <router-link :to="{name: 'articles.show', params: {id: item.slug}}">{{ item.url }}</router-link>
            </template>
        </app-table>
    </div> 
</template>
<script>
    import AppTable from '@/components/Table.vue';

    export default {
        components: {
            AppTable,
        },
        data() {
            return {
                columns: [                    
                    {
                        text: 'Title',
                        value: 'title',
                        sortable: false,
                    },
                    {
                        text: 'URL',
                        value: 'url',
                        slotName: 'item.url',
                        sortable: false,
                    },
                ],
                tableActions: []
            }
        },
    }
</script>
